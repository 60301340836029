// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import './styles.css';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from "react-redux";
import { findWellbores } from '../../api/well-search.api';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import wellsGeologyData from './test-data/wells-geology.json';
import wellGeologyDiagram from './insights/well-geology-diagram.png';
import BarChartIcon from '@material-ui/icons/BarChart';
import { img1 } from "./test-images/img1.png";
import { img2 } from "./test-images/img2.png";
import { img3 } from "./test-images/img3.png";
import { img4 } from "./test-images/img4.png";
import { img5 } from "./test-images/img5.png";
import LinearProgress from '@material-ui/core/LinearProgress';
import { fetchHistoricProductionTrends, fetchDuongDeclinePlot, fetchHistoricProductionScatter } from "../../api/plots.api";
// import { fetchRecentProductionScatter } from "../../api/plots.api";
// import { fetchRecentProductionTrends } from "../../api/plots.api";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    plotImage: {
        maxWidth: '1000px'
    }

}));

export function InsightsTabAnalysis(props: any) {
    const classes = useStyles();
    const [historicProductionTrends, setHistoricProductionTrends] = React.useState('');
    const [duongDeclinePlot, setDuongDeclinePlot] = React.useState('');
    const [historicProductionScatter, setHistoricProductionScatter] = React.useState('');
    const [showDuongDeclinePlotProgressBar, setShowDuongDeclinePlotProgressBar] = React.useState(true); 


    useEffect(() => {
        // immediately remove old data, and show the LinearProgress components
        setHistoricProductionTrends('');
        setDuongDeclinePlot('');
        setHistoricProductionScatter('');
        setShowDuongDeclinePlotProgressBar(true);

        fetchHistoricProductionTrends(props.currentWellName)
            .then(function (response) {
                console.log('response:', response);
                setHistoricProductionTrends(response['Data']);
            })
            .catch(function (error) {
                console.log(error);
            });

        fetchDuongDeclinePlot(props.currentWellName)
            .then(function (response) {
                console.log('response:', response);
                setDuongDeclinePlot(response['Data']);
            })
            .catch(function (error) {
                console.log(error.response);
                if(error.response && error.response.status && error.response.status === 500){
                    setShowDuongDeclinePlotProgressBar(false);
                }
            });

        fetchHistoricProductionScatter(props.currentWellName)
            .then(function (response) {
                console.log('response:', response);
                setHistoricProductionScatter(response['Data']);
            })
            .catch(function (error) {
                console.log(error);
            });

    }, [props.currentWellName]);

    return (
        <div style={{ maxHeight: 900, overflowX: 'hidden'  }} className={props.className}>
            <Grid container direction="column">
                <Grid item xs={6}>
                    {historicProductionTrends ? <img src={`data:image/png;base64,${historicProductionTrends}`} className={classes.plotImage}/>: <LinearProgress />}
                </Grid>
                <Grid item xs={6}>
                    {duongDeclinePlot ? <img src={`data:image/png;base64,${duongDeclinePlot}`} className={classes.plotImage}/>: showDuongDeclinePlotProgressBar && <LinearProgress />}
                </Grid>
                <Grid item xs={6}>
                    {historicProductionScatter ? <img src={`data:image/png;base64,${historicProductionScatter}`} className={classes.plotImage}/>: <LinearProgress />}
                </Grid>
                {/* <Grid item xs={6}>
                    <img src={require('./test-images/img1.png')} alt="img" />
                </Grid> */}
                {/* <Grid item xs={6}>
                    <img src={require('./test-images/img2.png')} alt="img" />
                </Grid> */}
                {/* <Grid item xs={6}>
                    <img src={require('./test-images/img3.png')} alt="img" />
                </Grid>
                <Grid item xs={6}>
                    <img src={require('./test-images/img4.png')} alt="img" />
                </Grid>
                <Grid item xs={6}>
                    <img src={require('./test-images/img5.png')} alt="img" />
                </Grid> */}
            </Grid>
        </div>
    );
}

export default InsightsTabAnalysis;