import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import { MainPage } from "./MainPage";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    leftBuffer: {
        paddingLeft: '14px'
    },
}));

/**
 * Contains login-logout functionality, search wells form,
 * found wells list and area for drawing weel trajectories
 */
export function ConnectedMainPage() {
    const classes = useStyles();
    const trajectories = useSelector(
        (state: AppState) => state.trajectory.trajectories
    );
    const isTrajectoryLoading = trajectories.some((t) => t.isLoading);
    const readyToDraw = trajectories.filter(
        (t) => t.isLoaded && t.loadError === undefined
    );
    const history = useHistory();

    const onOverviewClick = () => {
        history.push('map?lat=-25.0&lng=145.0&zoom=5')
    }

    return (
        <Grid container direction="column">
            <Grid item>
                <span className={classes.leftBuffer}/>
                <Button
                    variant="contained"
                    color="primary"
                    href="#contained-buttons"
                    onClick={onOverviewClick}
                >
                    Show map overview
                </Button>
            </Grid>
            <Grid item>
                <MainPage
                    isTrajectoryLoading={isTrajectoryLoading}
                    trajectoriesToDraw={readyToDraw}
                />
            </Grid>
        </Grid>
    );
}
