// @ts-nocheck

import {API} from "aws-amplify";

/**
 * Returns duong decline plot as base64 encoded png
 * @param {wellName} string
 */

export function fetchHistoricProductionTrends(wellName: string) {

    return API.get('api', `/api/sample-app/v1/plot/historic_production_trends_by_well_id?well_name=${wellName}&time=${Date.now()}`, {
        headers: {
            'data-partition-id': 'opendes',
        }
    })
}

export function fetchDuongDeclinePlot(wellName: string) {

    return API.get('api', `/api/sample-app/v1/plot/duong_decline_by_well_id?well_name=${wellName}&time=${Date.now()}`, {
        headers: {
            'data-partition-id': 'opendes',
        }
    })
}

export function fetchHistoricProductionScatter(wellName: string) {

    return API.get('api', `/api/sample-app/v1/plot/historic_production_scatter_by_well_id?well_name=${wellName}&time=${Date.now()}`, {
        headers: {
            'data-partition-id': 'opendes',
        }
    })
}
