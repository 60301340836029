// @ts-nocheck

import React, { useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import "./styles.css";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useDispatch, useSelector } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
// import { findWellbores } from "../../api/well-search.api";

import { InsightsTabEssentials } from "./InsightsTabEssentials";
import { InsightsTabDocuments } from "./InsightsTabDocuments";
import { InsightsTabProduction } from "./InsightsTabProduction";
import { InsightsTabAnalysis } from "./InsightsTabAnalysis";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    mainCard: {
        maxWidth: 1300,
        // maxHeight: 900,
        // overflowY: 'hidden',
        [theme.breakpoints.down('md')]: {
            maxWidth: 600,
        },
    }
}));

export function Insights(props: any) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [wellName, setWellName] = React.useState('N/A')

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const currentlySelectedWell = useSelector(
        (state: any) => state.map.currentlySelectedWell
    );
    const wells = useSelector((state: any) => state.map.wells);
    let currentWellName = '';
    if (currentlySelectedWell) {
        let currentWell = wells.filter(well => well.resourceId === currentlySelectedWell);
        currentWellName = currentWell.length && currentWell[0].facilityName ? currentWell[0].facilityName : '';
    }
    // useEffect(() => { }, []);

    return (
        <div className={props.className}>
            <Card className={classes.mainCard} >
                <CardContent>
                    {(currentlySelectedWell && wells.length > 0) ? (
                        <>
                            <Typography variant="h5">
                                {/* {wells.filter(well => well.resourceId === currentlySelectedWell)[0].facilityName} */}
                                {currentWellName}
                            </Typography>
                            <Typography variant="subtitle2">
                                Resource ID: {currentlySelectedWell}
                            </Typography>
                            <AppBar position="static">
                                <Tabs
                                    value={value}
                                    indicatorColor="primary"
                                    onChange={handleChange}
                                    aria-label="simple tabs example"
                                >
                                    <Tab label="Essentials" {...a11yProps(0)} />
                                    <Tab label="Production" {...a11yProps(1)} />
                                    <Tab label="Analysis" {...a11yProps(2)} />
                                    <Tab label="Documents" {...a11yProps(3)} />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={value} index={0}>
                                <InsightsTabEssentials currentWellName={currentWellName} />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <InsightsTabProduction currentWellName={currentWellName} />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <InsightsTabAnalysis currentWellName={/*'EOS-19' ||*/ currentWellName} />
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <InsightsTabDocuments currentWellName={currentWellName} />
                            </TabPanel>
                        </>
                    ) : (
                        <div>
                            <Typography variant="subtitle1">Please select a well</Typography>
                        </div>
                    )}
                </CardContent>
            </Card>
        </div>
    );
}

export default Insights;
