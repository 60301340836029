// @ts-nocheck

import {API} from "aws-amplify";

/**
 * Returns a list of documents
 * @param {wellName} string
 */
export function fetchDocumentList(wellName: string) {

    return API.get('api', `/api/sample-app/v1/find/list_document_resources_associated_with_well_name?well_name=${wellName}&time=${Date.now()}`, {
        headers: {
            'data-partition-id': 'opendes',
        }
    })
}
