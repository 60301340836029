// @ts-nocheck

import React, { useEffect, useState } from "react";
import { makeStyles, withStyles, Theme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import "./styles.css";
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboard } from "../../api/quicksight-dashboard.api";
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    }
}));

export function InsightsTabProduction(props: any) {
    const classes = useStyles();
    const [embedUrl, setEmbedUrl] = useState('');
    // var containerDiv = document.getElementById("dashboardContainer");
    // var options = {
    //     url: "<signed URL from Step 3>",
    //     container: containerDiv,
    //     parameters: {
    //         country: "United States",
    //     },
    //     scrolling: "no",
    //     height: "700px",
    //     width: "1000px",
    // };

    useEffect(() => {
        fetchDashboard()
            .then(function (response) {
                console.log(response.data["EmbedUrl"])
                // var index = 0;
                // index = response.data["EmbedUrl"].search("undoRedoDisabled=true")
                // console.log(index);
                // console.log(response.data["EmbedUrl"].substring(0, index));
                var true_url = response.data["EmbedUrl"];//.substring(0, index-1)
                setEmbedUrl(true_url);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [props.currentWellName]);

    return (

        <div>
            {embedUrl ?
                <iframe
                    id="Iframe"
                    src={embedUrl + `#p.Well=${props.currentWellName}`}
                    title="dashboard"
                    width="1000"
                    height="900"
                ></iframe>
                : <LinearProgress />}
        </div>
    );
}

export default InsightsTabProduction;
