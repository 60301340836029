import React from 'react';
import './styles.css';
import {ControlButton} from '../control-button';
import {ReactComponent as ClearPolygonIcon} from './assets/circle-crossed.svg';
import {ReactComponent as SearchPolygonIcon} from './assets/find-wells.svg';
import {ReactComponent as ShowChart} from './assets/show-chart.svg';
import {Link} from 'react-router-dom';
import DescriptionIcon from '@material-ui/icons/Description';

export interface ControlPanelProps {
    className?: string;
    /** flag to disable corresponding button on a control panel */
    isPolygonClearDisabled: boolean;

    /** flag to disable corresponding button on a control panel */
    isSearchInPolygonDisabled: boolean;

    /** flag to disable corresponding button on a control panel */
    isCrossSectionDisabled: boolean;

    /** search in polygon api request progress flag */
    isLoading: boolean;

    /** clear polygon button pressed */
    onPolygonClear: () => void;

    /** search in polygon button pressed */
    onSearchInPolygon: () => void;

    /** a link to a cross-section page in accordance with selected wells */
    crossSectionLink: string;

    onToggleInsights: () => void;
}

/**
 * sidebar panel to infuse 'search in polygon' functionality into a map
 * and to provide an actual link to a cross-section report
 */
export function ControlPanel({
                                 className = '',
                                 isPolygonClearDisabled,
                                 isSearchInPolygonDisabled,
                                 isCrossSectionDisabled,
                                 isLoading,
                                 onPolygonClear,
                                 onSearchInPolygon,
                                 crossSectionLink,
                                 onToggleInsights,
                             }: ControlPanelProps) {
    // it's defined separately as the clearest way I've come up with to avoid repetition
    const crossSectionButton = (
        <ControlButton
            title="View cross-section"
            icon={<ShowChart/>}
            disabled={isCrossSectionDisabled}
        />
    );

    return (
        <div className={`control-panel ${className}`}>
            <div className="control-panel__item">
                <ControlButton
                    title="Clear polygon"
                    icon={<ClearPolygonIcon/>}
                    disabled={isPolygonClearDisabled}
                    onClick={onPolygonClear}
                />
            </div>
            <div className="control-panel__item">
                <ControlButton
                    title="Search in polygon"
                    icon={<SearchPolygonIcon/>}
                    disabled={isSearchInPolygonDisabled}
                    onClick={onSearchInPolygon}
                    isLoading={isLoading}
                />
            </div>
            <div className="control-panel__item">
                {isCrossSectionDisabled ? (
                    crossSectionButton
                ) : (
                    <Link to={crossSectionLink}>{crossSectionButton}</Link>
                )}
            </div>
            <div className="control-panel__item">
                <ControlButton
                    title="View Insights"
                    icon={<DescriptionIcon/>}
                    disabled={false}
                    onClick={onToggleInsights}
                    isLoading={isLoading}
                />
            </div>
        </div>
    );
}

export default ControlPanel;
