import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LatLng } from "leaflet";
import {
    determineCurrentUserPositionAction,
    setInitialWellAction,
    Well,
    setUserPositionAction,
    setMapZoomAction,
    setMapCenterAction,
    addPointAction,
    finishDrawModeAction,
    findWellsAction,
    clearWellsAction
} from "../../store/map";
import { useLocation } from "react-router-dom";
import { MapPage } from "./MapPage";
import { AppState } from "../../store";

/**
 * Creates a map which includes a map view, control panel with buttons,
 * draw/edit polygon (the area that creates boundaries for finding wells),
 * select found wells on the map and passing these well ids to the draw cross-section page
 */
export function ConnectedMapPage() {
    const dispatch = useDispatch();
    const location = useLocation();
    const selectedWellIds = useSelector(
        (state: AppState) => state.map.selectedWellIds
    );
    const selectedmap = useSelector(
        (state: AppState) => state.map
    );
    const wells = useSelector((state: AppState) => state.map.wells);
    const selectedWells: Well[] = selectedWellIds.map(
        (id) => wells.find((w) => w.resourceId === id) as Well
    );

    // a certain well may be given to the page as a set of parameters in a url
    // lets try to parse it and set as initial data to work with
    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const lat = Number(query.get("lat"));
        const lng = Number(query.get("lng"));
        const wellId = query.get("wellId") || "";
        const wellName = query.get("wellName") || "";
        const zoom = query.get("zoom") || "";

        if (zoom === "") {
            dispatch(
                setInitialWellAction(
                    lat && lng && wellId && wellName
                        ? {
                              facilityName: wellName,
                              location: new LatLng(lat, lng),
                              resourceId: wellId,
                          }
                        : undefined
                )
            );
        } else {
            dispatch(setMapCenterAction(new LatLng(lat, lng)));
            dispatch(setMapZoomAction(parseInt(zoom)));

            const p1 = new LatLng(-7.538024053140275, 111.97265625000001);
            const p2 = new LatLng(-9.45511511468647, 162.42187500000003);
            const p3 = new LatLng(-44.38799729973159, 158.55468750000003);
            const p4 = new LatLng(-42.60360367627163, 109.77539062500001);

            // drawing points on map
            // dispatch(addPointAction(p1));
            // dispatch(addPointAction(p2));
            // dispatch(addPointAction(p3));
            // dispatch(addPointAction(p4));
            // dispatch(finishDrawModeAction());

            // starting well search
            dispatch(findWellsAction([
                {
                    id: "p11",
                    position: p1,
                    type: 'real'
                },
                {
                    id: "p12",
                    position: p2,
                    type: 'real'
                },
                {
                    id: "p13",
                    position: p3,
                    type: 'real'
                },
                {
                    id: "p14",
                    position: p4,
                    type: 'real'
                },
            ]));
        }
    }, [location.search, dispatch]);

    // lets popup that lovely "...wants to know your location" request
    // *solely for improving a user experience with this interface, we swear!
    useEffect(() => {
        dispatch(determineCurrentUserPositionAction());
    }, [dispatch]);

    return <MapPage selectedWells={selectedWells} />;
}
