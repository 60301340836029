// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import './styles.css';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from "react-redux";
import { findWellCompletionsData, fetchWelllogCurvePlot, fetchRecentProductionPlot } from '../../api/essentials.api';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
// import wellsGeologyData from './test-data/wells-geology.json';
import wellGeologyDiagram from './insights/well-geology-diagram.png';
import BarChartIcon from '@material-ui/icons/BarChart';
import LinearProgress from '@material-ui/core/LinearProgress';
// import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    wellGeologyLeftGrid: {
        // flexBasis: '420px'
    },
    wellGeologyImage: {
        // height: '98%'
        height: '520px'
    },
    wellGeologyTable: {
        paddingLeft: '14px',
        marginTop: '42px'
    },
    wellGeologyTableRow: {
        lineHeight: '12px'
    },
    wellGeologyTableKey: {
        fontSize: '11px'
    },
    wellGeologyTableValue: {
        fontSize: '11px',
        paddingLeft: '6px'
    },
    wellGeologyRightGrid: {
        // maxWidth: '800px'
    },
    welllogCurvePlotImage: {
        maxWidth: '610px',
        // minHeight: '461px',
        // minWidth: '400px',
        // maxHeight: '500px'
    },
    recentProductionImage: {
        // width: '300px',
        maxWidth: '1000px',
        // maxHeight: '500px'
    }
}));


/**
 * Returns truncated number (2 decimals) if param is a number
 * @param {value} any
 */
function truncateCompletionValues(value) {
    if (typeof value === "number") {
        // this truncates without rounding
        // return value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
        // thus uses rounding - from https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
        return Math.round((value + Number.EPSILON) * 100) / 100
    }
    return value;
}

export function InsightsTabEssentials(props: any) {
    const classes = useStyles();
    const [wellCompletionsData, setWellCompletionsData] = React.useState();
    const [recentProductionPlot, setRecentProductionPlot] = React.useState('');
    const [welllogCurvePlot, setWelllogCurvePlot] = React.useState('');
    // var resizebase64 = require('resize-base64');
    // const currentWellGeology = wellsGeologyData.filter(well => well.Well === "Eos 19")[0];

    // the number represents how many blank lines should follow the entry
    let wellGeologyFormat = [
        ['Base of perm', 2],
        ['Base of cement', 0],
        ['ACP centre depth', 1],
        ['WCM top', 0],
        ['Prognosed WCM top', 0],
        ['top coal', 6],
        ['Top Lower Mac', 1],
        ['Bottom Lower Mac', 3],
        ['Lowest Coal', 1],
        ['Pump Intake Depth', 0],
        ['Pump Size', 1],
        ['Casing Shoe Depth', 1],
        ['TD', 0],
        ['Prognosed TD', 0]
    ]

    useEffect(() => {
        // immediately remove old data, and show the LinearProgress component
        setWellCompletionsData(null);
        setWelllogCurvePlot('');
        setRecentProductionPlot('');

        findWellCompletionsData(props.currentWellName)
            .then(function (response) {
                console.log('response completion data:', response);
                setWellCompletionsData(response);
            })
            .catch(function (error) {
                console.log(error);
            });


        fetchWelllogCurvePlot(props.currentWellName)
            .then(function (response) {
                console.log('response:', response);
                // const img = resizebase64(response['Data'], '100px', '96px')
                // console.log('img'+ img)
                setWelllogCurvePlot(response['Data']);
            })
            .catch(function (error) {
                console.log(error);
            });


        fetchRecentProductionPlot(props.currentWellName)
            .then(function (response) {
                console.log('response:', response);
                setRecentProductionPlot(response['Data']);
            })
            .catch(function (error) {
                console.log(error);
            });

    }, [props.currentWellName]);

    return (
        <div style={{ maxHeight: 900, overflowX: 'hidden' }} className={props.className}>

            {wellCompletionsData ?
                <Grid container direction="row" spacing={2} >

                    <Grid container item xs={12} spacing={0} className={classes.wellGeologyLeftGrid}>
                        <Grid container item xs={5}>
                            <Grid item xs={12}>
                                <Typography variant="h6" align="center" gutterBottom>
                                    <span style={{ marginLeft: '-10%' }} >Completion Info</span>
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <div ><img src={wellGeologyDiagram} className={classes.wellGeologyImage} alt="Well Geology Diagram" /></div>
                            </Grid>
                            <Grid item xs={7}>
                                <table className={classes.wellGeologyTable}>
                                    <tbody>
                                        {wellGeologyFormat.map(item => {
                                            return (
                                                <>
                                                    <tr className={classes.wellGeologyTableRow}>
                                                        <td className={classes.wellGeologyTableKey} align="right">{wellCompletionsData.GeologyData[item[0]] ? truncateCompletionValues(wellCompletionsData.GeologyData[item[0]]) : ''}</td>
                                                        <td className={classes.wellGeologyTableValue} >{item[0]}</td>
                                                    </tr>
                                                    {/* add empty rows based on the number in wellGeologyFormat  */}
                                                    {[...Array(item[1])].map((x, i) => <tr className={classes.wellGeologyTableRow} key={i}><td>&nbsp;</td><td>&nbsp;</td></tr>)}
                                                </>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </Grid>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant="h6" align="center" gutterBottom >Well Composite</Typography>
                            <Box style={{ textAlign: "center" }}>
                                {welllogCurvePlot ? <img className={classes.welllogCurvePlotImage} src={`data:image/png;base64,${welllogCurvePlot}`} /> : <LinearProgress />}
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h6" align="center" gutterBottom >Drilling Notes</Typography>
                        <Typography variant="body2" >{wellCompletionsData.DrillingNotes}</Typography>
                    </Grid>

                    <Grid item className={classes.wellGeologyRightGrid} xs={12}>
                        <Typography variant="h6" align="center" gutterBottom >Recent Production</Typography>
                        <Box style={{ textAlign: "center" }}>
                            {recentProductionPlot ? <img className={classes.recentProductionImage} src={`data:image/png;base64,${recentProductionPlot}`} /> : <LinearProgress />}
                        </Box>
                    </Grid>
                </Grid>
                : <LinearProgress />
            }

        </div>
    );
}

export default InsightsTabEssentials;