import React from "react";
import "./styles.css";
import { Well } from "../../../store/map";
import { makeStyles } from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store";

export interface SelectedWellsProps {
    className?: string;
    wells: Well[];
}

const useStyles = makeStyles({
    root: {
      minWidth: 375,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  });

export const SelectedWells = ({
    wells,
    className = "",
}: SelectedWellsProps) => {
    const selectedWellIds = useSelector(
        (state: AppState) => state.map.selectedWellIds
    );
    const allWells = useSelector((state: AppState) => state.map.wells);
    const selectedWells: Well[] = selectedWellIds.map(
        (id) => allWells.find((w) => w.resourceId === id) as Well
    );
    const isInsight = useSelector((state: AppState) => state.map.insightSelection);
    // { !isInsight &&
    const classes = useStyles();
    return (
        <>
            {!isInsight && <Card className={classes.root}>
                <CardContent>
                    <div className={`selected-wells ${className}`}>
                        <div className="selected-wells__header">
                            Wells for
                            <br />
                            cross-section
                        </div>
                        {(selectedWells.length > 0 && selectedWells[0] !== undefined) && <ul className="selected-wells__list">
                            {selectedWells.map((w, i) => (
                                <>{w !== undefined &&
                                <li key={w.resourceId}>{w.facilityName}</li>}</>
                            ))}
                        </ul>}
                        {/* <div>test</div> */}
                    </div>
                </CardContent>
            </Card>}
        </>
    );
};
