// @ts-nocheck

import React, { useEffect, useState } from "react";
import { makeStyles, withStyles, Theme } from "@material-ui/core/styles";

import "./styles.css";
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import { fetchDocumentList } from "../../api/documents.api";

import Table from "@material-ui/core/Table";
import TablePagination from "@material-ui/core/TablePagination";
import TableFooter from "@material-ui/core/TablePagination";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme: Theme) => ({
    mainDiv: {
        maxHeight: 900,
        overflowX: 'hidden'
    }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#0165A6",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

export function InsightsTabDocuments(props: any) {
    const classes = useStyles();

    const [documentList, setDocumentList] = React.useState();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);

    const rowCount = documentList ? documentList.length : 0;

    const emptyRows =
        rowsPerPage -
        Math.min(
            rowsPerPage,
            rowCount - page * rowsPerPage
        );

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        // immediately remove old data, and show the LinearProgress component
        setDocumentList(null);

        fetchDocumentList(props.currentWellName)
            .then(function (response) {
                console.log('response:', response['Documents']);
                setDocumentList(response['Documents']);
            })
            .catch(function (error) {
                console.log(error);
            });

    }, [props.currentWellName]);

    return (
        <div className={classes.mainDiv}>
            {documentList ? (
                <>
                    <TableContainer component={Paper}>
                        <Table
                            className={classes.table}
                            aria-label="customized table"
                        >
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Name</StyledTableCell>
                                    <StyledTableCell>Link</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {documentList
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map(
                                        // Object.entries(currentWellDocs["Documents"]).map(
                                        (document) => (
                                            <StyledTableRow>
                                                <StyledTableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {document['Name']}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    <Link
                                                        target={"_blank"}
                                                        href={document['AccessorUrl']}
                                                        key={document['Name']}
                                                    >
                                                        Link
                                                    </Link>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    )}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{ height: 53 * emptyRows }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 20]}
                        count={rowCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        component="div"
                    />
                </>
            ) : <LinearProgress />}
        </div>
    );
}

export default InsightsTabDocuments;
