// @ts-nocheck
import {AppState} from '../index';
import {ThunkAction} from 'redux-thunk';
import {LatLng, Polygon} from 'leaflet';
import {Polygon as GeoPolygon} from 'geojson';
import {
    ADD_POINT,
    CHANGE_POINT,
    CLEAR_ALL_POINTS,
    CLEAR_SEARCH_ERROR,
    DELETE_POINT,
    FIND_WELLS_FAIL,
    FIND_WELLS_START,
    FIND_WELLS_SUCCESS,
    FINISH_DRAW_MODE,
    MAKE_POINT_REAL,
    MapActionTypes,
    SET_INITIAL_WELL,
    SET_MAP_CENTER,
    SET_MAP_ZOOM,
    SET_USER_POSITION,
    TOGGLE_WELL_SELECTION,
    TOGGLE_INSIGHT_SELECTION,
    CLEAR_WELLS
} from './types';
import {fetchWellsInPolygon, FetchWellsInPolygonResponse} from '../../api/map.api';
import {PolygonPoint, Well} from './reducer';

type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, null, MapActionTypes>;

export function setInitialWellAction(well?: Well): MapActionTypes {
    return {
        type: SET_INITIAL_WELL,
        payload: well,
    };
}

/**
 * toggle well to be included into a cross-section report
 * @param wellId
 */
export function toggleWellSelectionAction(wellId: string): MapActionTypes {
    return {
        type: TOGGLE_WELL_SELECTION,
        payload: wellId,
    };
}

export function setUserPositionAction(position: LatLng): MapActionTypes {
    return {
        type: SET_USER_POSITION,
        payload: position,
    };
}

/**
 * comand to finish a draw mode
 * and to enclose a polygon with a given set of points
 */
export function finishDrawModeAction(): MapActionTypes {
    return {
        type: FINISH_DRAW_MODE,
    };
}

/**
 * add a new point to a polygon
 * @param position
 */
export function addPointAction(position: LatLng): MapActionTypes {
    return {
        type: ADD_POINT,
        payload: position,
    };
}

/**
 * change a position of a polygon point
 * @param id
 * @param position a new geo position
 */
export function changePointAction(id: string, position: LatLng): MapActionTypes {
    return {
        type: CHANGE_POINT,
        payload: {
            id,
            position: position,
        },
    };
}

/**
 * remove a single polygon point
 * @param id
 */
export function deletePointAction(id: string): MapActionTypes {
    return {
        type: DELETE_POINT,
        payload: id,
    };
}

/** comand to convert an 'edit' point to a real one */
export function makePointRealAction(id: string): MapActionTypes {
    return {
        type: MAKE_POINT_REAL,
        payload: id,
    };
}

/** remove any polygon points and start its drawing anew */
export function clearAllPointsAction(): MapActionTypes {
    return {
        type: CLEAR_ALL_POINTS,
    };
}

export function findWellsStartAction(): MapActionTypes {
    return {
        type: FIND_WELLS_START,
    };
}

export function findWellsSuccessAction(wells: FetchWellsInPolygonResponse): MapActionTypes {
    return {
        type: FIND_WELLS_SUCCESS,
        payload: wells,
    };
}

export function findWellsFailAction(err: Error): MapActionTypes {
    return {
        type: FIND_WELLS_FAIL,
        payload: err,
    };
}

/**
 * This will keep the state synced with a map zoom,
 * changed by user's interactions with a leaflet
 * @param zoom a new map zoom value
 */
export function setMapZoomAction(zoom: number): MapActionTypes {
    return {
        type: SET_MAP_ZOOM,
        payload: zoom,
    };
}

/**
 * This will keep the state synced with a map postition
 * changed by user's interactions with a leaflet
 * @param center a new map center coordinates
 */
export function setMapCenterAction(center: LatLng): MapActionTypes {
    return {
        type: SET_MAP_CENTER,
        payload: center,
    };
}

/**
 * mark that a user acknowledged and error an it should be hidden now
 */
export function clearSearchError(): MapActionTypes {
    return {
        type: CLEAR_SEARCH_ERROR,
    };
}

export function toggleInsightSelectionAction(): MapActionTypes {
    return {
        type: TOGGLE_INSIGHT_SELECTION,
    };
}

export function clearWellsAction(): MapActionTypes {
    return {
        type: CLEAR_WELLS,
    };
}

/** try to determine user's position */
export const determineCurrentUserPositionAction = (): AppThunk => dispatch => {
    return new Promise(resolve => {
        window.navigator.geolocation.getCurrentPosition(
            position => {
                const userPosition = new LatLng(position.coords.latitude, position.coords.longitude);
                dispatch(setUserPositionAction(userPosition));
                resolve();
            },
            error => {
                // User position haven't got. Just get over it, you know.
                resolve();
            }
        );
    });
};

/**
 * Fetch from the backend a list of wells, located within a given polygon, to draw on the map
 * @param points a set of geo point, forming a valid polygon
 */
export const findWellsAction = (points: PolygonPoint[]): AppThunk => async dispatch => {
    dispatch(findWellsStartAction());
    dispatch(clearWellsAction());
    const geoPints: LatLng[] = points.filter(p => p.type === 'real').map(p => p.position);
    // the type is Polygon | MultiPolygon, but we do not work with MultiPolygon
    const geometry = new Polygon(geoPints).toGeoJSON().geometry as GeoPolygon;

    var body_to_send = {
        ...geometry,
        "type": "Polygon",
        "with_cursor": "True",
    }

    var current_data = {};
    var end_reached = false;

    while(!end_reached) {
        dispatch(findWellsStartAction());
        const p = fetchWellsInPolygon(body_to_send)
            .then(data => {
                dispatch(findWellsSuccessAction(data))
                current_data = data
                if (data["cursor"] === null) {
                    end_reached = true;
                }
                if (data["wells"] && data["wells"].length === 0) {
                    end_reached = true;
                }
                body_to_send = {
                    "cursor": current_data["cursor"],
                    "with_cursor": "True",
                }
            })
            .catch((err: Error) => dispatch(findWellsFailAction(err)));
        await p
    }
    return;
};
