import React from "react";
import Cards from "../../components/cards/Cards";
import ExploreIcon from "@material-ui/icons/Explore";
import Services from "./Services";
import OSDUTestDataSet from "./OSDUTestDataSet";
import AWSIntegration from "./AWSIntegration";
import Endpoints from "./Endpoints";
import OSDUTutorialsSampleApplication from "./OSDUTutorialsSampleApplication";
import {Link} from "react-router-dom";

const TITLE = "Explore Current Implementation";
const URL = "/".concat(TITLE.replace(/\s+/g, ''));
const DESCRIPTION = <>
                To enable you to explore OSDU™ 
                <Link className="text-link" to="/Services"> services </Link>
                and
                <Link className="text-link" to="/OSDUTestDataSet"> data </Link>
                provided out-of-the-box, your Preview Deployment of
                47Lining Enterprise PaaS provides the data platform
                APIs together with pre-loaded sample datasets and the
                tutorial
                <Link className="text-link" to="/OSDUR2VisualizationApp"> sample application </Link>
                published by the OSDU Forum.  This makes it easy for
	        you to get started right away to interact with and
	        explore the code, data, APIs, and typical application
	        usage.  From this Preview Deployment Portal you can
	        access a
                <Link className="text-link" to="/ManagementConsole"> management console  </Link>
                user interface to selected data platform services.
	        You can also explore material that explains key
	        concepts of the OSDU Data Platform and how to leverage
	        the platform in a production operations
	        environment. Finally, you can learn about how your
	        Preview Deployment is built on top of
                <Link className="text-link" to="/AWSIntegration"> AWS </Link>
		and which underlying services are used.
    </>;

const ITEMS = [
    {
        title: "Data Platform Services Overview",
        description:
            <>
                Learn about the OSDU Data Platform services that are part of the OSDU Standard, each of which are included in your Preview Deployment.
            </>
        ,
        path: '/Services',
        component: Services
    }, {
        title: "Test Data Sets",
        description:
            <>
                Learn about the Wells and Seismic domain&nbsp;
		<a className='text-link'
		   rel="noopener noreferrer"
		   target="_blank"
               	   href='https://community.opengroup.org/osdu/platform/open-test-data'>
		    test datasets
		</a>
		&nbsp;published by the OSDU Forum and included in your
		Preview Deployment.
            </>

        ,
        path: '/OSDUTestDataSet',
        component: OSDUTestDataSet
    },
    {
        title: "Your OSDU Service Endpoints",
        description:
            <>
                Learn how and where to access each of the OSDU Data Platform service endpoints included in your Preview Deployment of 47Lining Enterprise PaaS.
            </>
        ,
        path: '/Endpoints',
        component: Endpoints
    },
    {
        title: "Management Console",
        description:
            <>
                Exercise data platform service endpoints through a
                console user interface without writing any code.
            </>
        ,
        path: '/ManagementConsole'
    },
    {
        title: "OSDU™ Tutorials Sample Application",
        description:
            <>
                Learn about the sample application made available by
                the OSDU Forum and included in your Preview Deployment
                that demonstrates data platform services acting on
                test datasets.
            </>
        ,
        path: '/OSDUTutorialsSampleApplication',
        component: OSDUTutorialsSampleApplication
    }, {
        title: "AWS Integration",
        description:
            <>
                Learn about how your Preview Deployment uses AWS services to support deployment, implementation, and operations of the included OSDU Data Platform services.
            </>
        ,
        path: '/AWSIntegration',
        component: AWSIntegration
    }
    
]

export default [
    {
        name: TITLE,
        path: URL,
        component: () => (<Cards header={{'title': TITLE, 'description': DESCRIPTION}} items={ITEMS}/>),
        addToSideMenu: true,
        wrappedWithNavigation: true,
        exact: true,
        icon: <ExploreIcon/>
    },
    ...ITEMS.filter(i => i.component).map(i => {
        return {
            path: i.path,
            component: i.component,
            wrappedWithNavigation: true,
            exact: true
        }
    })
]
