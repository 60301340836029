import React from 'react';
import './styles.css';
import {Icon, LeafletMouseEvent} from 'leaflet';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from '../../../store';
import {Marker, Tooltip} from 'react-leaflet';
import wellMarker from './assets/well-marker.svg';
import {Well} from '../../../store/map';
// import { current } from 'immer';

export interface WellPointProps {
    /** a well model to be represented as a marker on a map */
    well: Well;

    /** adds a 'selected' styles */
    isSelected: boolean;

    /** tweaks color scheme */
    type?: 'default' | 'initial';

    onToggleSelected: (wellId: string) => void;
}

/** Represents well as a marker on a map */
export function WellPoint({
                              well,
                              isSelected,
                              onToggleSelected,
                              type = 'default',
                          }: WellPointProps) {
    const handleMarkerClick = () => {
        onToggleSelected(well.resourceId);
    };

    const handleDblClick = (e: LeafletMouseEvent) => {
        // prevent an irritating map zoom
        // whenever a user toggles a selected state twice, fast enough to fire a dblclick
        e.originalEvent.stopPropagation();
    };

    const isInsight = useSelector((state: AppState) => state.map.insightSelection);
    const currentlySelectedWell = useSelector((state: AppState) => state.map.currentlySelectedWell);
    const markerClassList = ['well-marker'];

    if (type === 'initial') {
        markerClassList.push('well-marker--initial');
    }
    if (isSelected && !isInsight) {
        markerClassList.push('well-marker--selected');
    }
    if (currentlySelectedWell === well.resourceId && isInsight) {
        markerClassList.push('well-marker--selected-insight');
    }
    // an icon for a Marker
    const wellIcon = new Icon({
        iconUrl: wellMarker,
        iconSize: [26, 26],
        className: markerClassList.join(' '),
    });

    return (
        <Marker
            onClick={handleMarkerClick}
            onDblClick={handleDblClick}
            icon={wellIcon}
            position={well.location}>
            <Tooltip direction="left" offset={[-12, 0]}>
                <b>{well.facilityName}</b>
                <br/>
                wellId: {well.resourceId}
            </Tooltip>
        </Marker>
    );
}
