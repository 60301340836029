import {Polygon} from 'geojson';
import {API} from "aws-amplify";

type FetchWellsInPolygonRequest = Polygon;

export type FetchWellsInPolygonResponse = {
    wells: FetchWellInPolygonResponse[]
};

/* eslint-disable @typescript-eslint/camelcase */
interface FetchWellInPolygonResponse {
    resource_id: string;
    facility_name: string;
    location: {
        coordinates: [number, number];
        type: string;
    };
}

/* eslint-enable @typescript-eslint/camelcase */

/**
 * Returns a list of wells, located within a given polygon
 * no pagination is implemented, results are limited by the backend (99 atm)
 * Error handler is included
 * @param {Polygon} polygon
 */
// export function fetchWellsInPolygon(polygon: Polygon): Promise<FetchWellsInPolygonResponse> {
export function fetchWellsInPolygon(body: any): Promise<FetchWellsInPolygonResponse> {
    // const body: FetchWellsInPolygonRequest = polygon;

    return API.post('api', `/api/sample-app/v1/find/wells_in_polygon`, {
        headers: {
            'data-partition-id': 'opendes',
        },
        body: body
    })
}
