// @ts-nocheck

import {API} from "aws-amplify";

/**
 * Returns well completions data
 * @param {wellName} string
 */
export function findWellCompletionsData(wellName: string) {

    return API.get('api', `/api/sample-app/v1/find/find_well_completions_data_for_well_name?well_name=${wellName}&time=${Date.now()}`, {
        headers: {
            'data-partition-id': 'opendes',
        }
    })
}

/**
 * Returns welllog curves plot as base64 encoded png
 * @param {wellName} string
 */
 export function fetchWelllogCurvePlot(wellName: string) {

    return API.get('api', `/api/sample-app/v1/plot/welllog_curve_by_well_id?well_name=${wellName}&time=${Date.now()}`, {
        headers: {
            'data-partition-id': 'opendes',
        }
    })
}

/**
 * Returns recent_production trends plot as base64 encoded png
 * @param {wellName} string
 */
 export function fetchRecentProductionPlot(wellName: string) {

    return API.get('api', `/api/sample-app/v1/plot/recent_production_trends_by_well_id?well_name=${wellName}&time=${Date.now()}`, {
        headers: {
            'data-partition-id': 'opendes',
        }
    })
}
